/**
 * @format
 */
@import url("https://fonts.googleapis.com/css2?family=Lugrasimo&display=swap");
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
*,
::after,
::before {
  box-sizing: border-box;
}
img {
  -webkit-user-drag: none; /* Disable dragging of image */
  -webkit-user-select: none; /* Disable selecting of image */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img:after {
  content: ""; /* Add content */
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1; /* Make sure it's on top of the image */
  background-color: rgba(
    0,
    0,
    0,
    0
  ); /* Set the background color to transparent */
}

img::-webkit-context-menu {
  display: none; /* Disable right-click context menu on Webkit browsers */
}

img::-moz-context-menu {
  display: none; /* Disable right-click context menu on Firefox */
}

img:focus,
img:active {
  outline: none; /* Remove outline on click or focus */
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body::-webkit-scrollbar-track {
  background: #f7fbff !important;
}

body::-webkit-scrollbar-thumb {
  background: #ffa800 !important;
  border-radius: 16px;
}

body::-webkit-scrollbar {
  width: 3px;
}
footer,
header,
nav {
  display: block;
}
.bg-hero {
  background: #2e2d2c;
}
video {
  width: 100% !important;
  object-fit: cover;
  border: 0 !important;
  height: 90vh;
  @media only screen and (max-width: 600px) {
    height: 50vh;
  }
}
body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  //   "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  //   "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  // background-color: #fff;
}
h1,
h2,
h3,
h4,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ul ul {
  margin-bottom: 0;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
h1,
h2,
h3,
h4,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h6 {
  font-size: 1rem;
}
a {
  cursor: pointer !important;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1260px;
  }
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-12,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-md-12,
.col-md-4,
.col-md-6,
.col-sm-12,
.col-sm-6,
.col-xl-2,
.col-xl-4,
.col-xl-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 576px) {
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.sec-bg {
  background: #0c111b;
  position: relative;
  min-height: auto;
  padding: 40px 0;
  padding-bottom: 60px;
}
.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}
.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.position-relative {
  position: relative !important;
}
.w-100 {
  width: 100% !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 992px) {
  .text-lg-right {
    text-align: right !important;
  }
}
.text-white {
  color: #fff !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  img {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
}
/*! CSS Used from: http://rainbowit.net/html/imroz/assets/css/plugins/feature.css */
[class*=" feather-"] {
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.feather-activity:before {
  content: "\e900";
}
.feather-award:before {
  content: "\e919";
}
.feather-cast:before {
  content: "\e92a";
}
.feather-clock:before {
  content: "\e939";
}
.feather-codepen:before {
  content: "\e941";
}
.feather-gitlab:before {
  content: "\e971";
}
.feather-heart:before {
  content: "\e977";
}
.feather-map:before {
  content: "\e98b";
}
.feather-menu:before {
  content: "\e98f";
}
.feather-send:before {
  content: "\e9c2";
}
.feather-shield:before {
  content: "\e9c7";
}
.feather-stop-circle:before {
  content: "\e9d6";
}
.feather-x:before {
  content: "\ea02";
}
/*! CSS Used from: http://rainbowit.net/html/imroz/assets/css/vendor/fontawesome.css */
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-twitter:before {
  content: "\f099";
}
.fab {
  font-family: "Font Awesome 5 Brands";
}
/*! CSS Used from: http://rainbowit.net/html/imroz/assets/css/style.css */
:root {
  --color-primary: #ffa800;
  --color-secondary: #337dff;
  --color-secondary-rgb: rgba(253, 71, 102, 1);
  --color-tertiary: #010001;
  --color-dark: #000000;
  --color-heading: #1f1f25;
  --color-body: #1d1d24;
  --color-gray: rgba(29, 29, 36, 0.75);
  --color-midgray: #e9ecef;
  --color-opacity-1: hsla(0, 0%, 100%, 0.2);
  --color-light: #e4e6ea;
  --color-lighter: #ced0d4;
  --color-lightest: #f0f2f5;
  --color-border: #e6e6e6;
  --color-white: #ffffff;
  --color-success: #3eb75e;
  --color-danger: #ff0003;
  --color-warning: #ff8f3c;
  --color-info: #1ba2db;
  --color-facebook: #3b5997;
  --color-twitter: #1ba1f2;
  --color-youtube: #ed4141;
  --color-linkedin: #0077b5;
  --color-pinterest: #e60022;
  --color-instagram: #c231a1;
  --color-vimeo: #00adef;
  --color-twitch: #6441a3;
  --color-discord: #7289da;
  --color-extra01: #666666;
  --color-extra02: #606770;
  --color-extra03: #fbfbfd;
  --color-extra04: #1a1a1a;
  --color-extra05: #242424;
  --radius: 10px;
  --radius-big: 16px;
  --radius-small: 6px;
  --border-width: 2px;
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;
  --s-light: 300;
  --s-regular: 400;
  --s-medium: 500;
  --s-bold: 700;
  --s-black: 900;
  --shadow-primary: 0px 4px 10px rgba(37, 47, 63, 0.1);
  --shadow-light: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  --shadow-dark: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  --transition: 0.3s;
  --font-primary: "Poppins", sans-serif;
  --secondary-font: "Poppins", sans-serif;
  --font-awesome: "Font Awesome 5 Pro";
  --font-size-b1: 18px;
  --font-size-b2: 16px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;
  --line-height-b1: 1.67;
  --line-height-b2: 1.5;
  --line-height-b3: 1.6;
  --line-height-b4: 1.3;
  --h1: 44px;
  --h2: 36px;
  --h3: 30px;
  --h4: 24px;
  --h5: 18px;
  --h6: 16px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
footer,
header,
nav {
  display: block;
}
html {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}
a {
  color: #ca3c08;
  text-decoration: none;
}
a:visited {
  color: #ac0404;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  color: #ea9629;
  outline: 0;
}
a:hover {
  text-decoration: underline;
}
p {
  margin: 0 0 24px;
}
ul {
  margin: 16px 0;
  padding: 0 0 0 40px;
}
ul {
  list-style-type: square;
}
nav ul {
  list-style: none;
  list-style-image: none;
}
li > ul {
  margin: 0;
}
img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
}
svg:not(:root) {
  overflow: hidden;
}
* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
html {
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}
body {
  overflow: hidden;
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-primary);
  color: var(--color-gray);
  font-weight: 400;
}
a {
  transition: all 0.4s ease-in-out 0s;
}
h1,
h2,
h3,
h4,
h6,
p,
ul {
  margin: 0;
  margin-bottom: 15px;
}
h1,
h2,
h3,
h4,
h6 {
  font-family: "Poppins", sans-serif;
  line-height: 1.4074;
  color: #1f1f25;
}
h1 {
  font-size: var(--h1);
}
h2 {
  font-size: var(--h2);
}
h3 {
  font-size: var(--h3);
}
h4 {
  font-size: var(--h4);
}
h6 {
  font-size: var(--h6);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: var(--h1)-4;
  }
  h2 {
    font-size: var(--h2)-2;
  }
  h3 {
    font-size: var(--h3)-2;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: var(--h1)-6;
  }
  h2 {
    font-size: var(--h2)-4;
  }
  h3 {
    font-size: var(--h3)-2;
  }
}
@media only screen and (max-width: 575px) {
  h1 {
    font-size: var(--h1)-8;
  }
}
h1,
h2,
h3 {
  font-weight: 700;
}
h4 {
  font-weight: 600;
}
h6 {
  font-weight: 500;
}
p {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  p {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}
@media only screen and (max-width: 767px) {
  p br {
    display: none;
  }
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}
a:visited {
  color: inherit;
}
.mainmenunav ul.mainmenu,
.mainmenunav ul.mainmenu > li > ul.submenu,
.rn-popup-mobile-menu ul.mainmenu,
.rn-popup-mobile-menu ul.mainmenu li ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.liststyle {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer-style-01 .footer-link ul.ft-link li a::after,
.footer-style-01 .ft-text p a::after,
.mainmenunav ul.mainmenu > li > a,
.mainmenunav ul.mainmenu > li > ul.submenu,
.mainmenunav ul.mainmenu > li > ul.submenu li a,
a.btn-transparent,
a.btn-transparent::after,
a.rn-btn,
ul.social-share li a,
.service-main-wrapper .service.service__style--2 .icon,
.service-main-wrapper .service.service__style--2 .content h3.title,
.service-main-wrapper .service.service__style--2 .content p,
ul.testimonial-thumb-wrapper li .thumb img,
ul.testimonial-thumb-wrapper li .thumb::after,
.im_box .content .title a,
.footer-style-01 .footer-link ul.ft-link li a {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.service-main-wrapper .service.service__style--2 {
  position: relative;
}
p:last-child {
  margin-bottom: 0;
}
.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.footer-style-01 .footer-link ul.ft-link li a::after,
.footer-style-01 .ft-text p a::after,
a.btn-transparent::after {
  // background: linear-gradient(
  //   120deg,
  //   #1c99fe 20.69%,
  //   #7644ff 50.19%,
  //   var(--color-primary) 79.69%
  // );
  background: #ffa800;
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color-primary)', endColorstr='var(--color-secondary)',GradientType=1 );
}
.theme-gradient,
a.btn-transparent,
.section-title span.subtitle {
  color: #606770;
  // background: var(--color-primary);
  // background: linear-gradient(
  //   120deg,
  //   #1c99fe 20.69%,
  //   #7644ff 50.19%,
  //   var(--color-primary) 79.69%
  // );
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color-primary)', endColorstr='var(--color-secondary)',GradientType=1 );
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  display: inline-block;
}
.footer-style-01 .footer-link ul.ft-link li a,
.footer-style-01 .ft-text p a {
  position: relative;
}
.footer-style-01 .footer-link ul.ft-link li a::after,
.footer-style-01 .ft-text p a::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
}
.footer-style-01 .footer-link ul.ft-link li a:hover::after,
.footer-style-01 .ft-text p a:hover::after {
  width: 100%;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1260px;
  }
}
.im-separator {
  position: relative;
}
.im-separator::after {
  position: absolute;
  content: "";
  background-color: var(--color-opacity-1);
  max-width: 1230px;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
}
.bg_color--1 {
  background: #fff;
}
.bg_color--2 {
  background: #7c7d7f;
}
.bg_color--5 {
  background: #f9f9f9;
}
.bg_color--6 {
  background: #010001;
}
.bg_image--1 {
  background-image: url(http://rainbowit.net/html/imroz/assets/images/bg/bg-image-1.jpg) !important;
}
p:last-child {
  margin-bottom: 0;
}
.row--35 {
  margin-left: -35px;
  margin-right: -35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--35 {
    margin-left: -25px;
    margin-right: -25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 {
    margin-left: -25px;
    margin-right: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--35 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--35 > [class*="col"],
.row--35 > [class*="col-"] {
  padding-left: 35px;
  padding-right: 35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.row--25 {
  margin-left: -25px;
  margin-right: -25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--25 {
    margin-left: -25px;
    margin-right: -25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 {
    margin-left: -25px;
    margin-right: -25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--25 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--25 > [class*="col"],
.row--25 > [class*="col-"] {
  padding-left: 25px;
  padding-right: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
[data-black-overlay] {
  position: relative;
  z-index: 1;
}
[data-black-overlay] > div,
[data-black-overlay] > * {
  position: relative;
  z-index: 1;
}
[data-black-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
[data-black-overlay]:before {
  background-color: #010001;
}
[data-black-overlay="8"]:before {
  opacity: 0.8;
}
.thumb img,
.thumbnail img {
  border-radius: 6px;
}
.mb--0 {
  margin-bottom: 0 !important;
}
.pl--20 {
  padding-left: 20px;
}
.mt--20 {
  margin-top: 20px !important;
}
.mt--30 {
  margin-top: 30px !important;
}
.mb--30 {
  margin-bottom: 30px;
}
.ptb--70 {
  padding: 70px 0;
}
.ptb--120 {
  padding: 120px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb--120 {
    padding: 80px 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .ptb--120 {
    padding: 80px 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt_md--20 {
    margin-top: 20px !important;
  }
  .mt_md--40 {
    margin-top: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl_sm--10 {
    padding-left: 10px !important;
  }
  .mt_sm--20 {
    margin-top: 20px !important;
  }
  .mt_sm--40 {
    margin-top: 40px !important;
  }
}
@media only screen and (max-width: 575px) {
  .mt_mobile--40 {
    margin-top: 40px !important;
  }
}
.header-area .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  flex-wrap: wrap;
  z-index: 2;
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper {
    padding: 10px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper {
    padding: 10px 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area .header-wrapper {
    padding: 10px 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .logo a img {
    width: 120px;
  }
}
.header-area.header--transparent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  padding-left: 50px;
  padding-right: 50px;
  z-index: 999;
}
.header-area.header--transparent .header-wrapper {
  padding: 10px 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area.header--transparent {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .header-area.header--transparent {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .header-area.header--transparent {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.header-area .logo img {
  max-width: 150px;
}
.header-area .logo span {
  font-size: 30px;
  font-weight: 600;
  color: #dce1e5;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area a.btn-default {
    font-size: 14px;
    letter-spacing: 0;
    padding: 10px 27px;
  }
}
@media only screen and (max-width: 767px) {
  .header-area a.btn-default {
    font-size: 14px;
    letter-spacing: 0;
    padding: 7px 16px;
  }
}
.header-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.header-right .header-btn {
  margin-left: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right .header-btn {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-right .header-btn {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .header-right .header-btn {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .header-right .header-btn {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav {
    position: absolute;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav {
    position: absolute;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu {
    width: 60vw;
    height: 100vh;

    position: fixed;
    top: 0;
    right: -61vw;
    z-index: 99;
    padding: 55px;
    background: #ffffff;
    -webkit-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    -o-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    display: block;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu {
    width: 60vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -61vw;
    z-index: 99;
    padding: 55px;
    background: #ffffff;

    -webkit-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    -o-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
    display: block;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li {
    margin: 10px;
    padding: 10px 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-dropdown {
    position: relative;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    padding: 0;
    display: block;
    color: #ffffff;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    padding: 0;
    display: block;
    color: #dce1e5;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu {
    position: relative;
    height: 0;
    transition: 0.3s;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    background: transparent;
    box-shadow: inherit;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu {
    position: relative;
    height: 0;
    transition: 0.3s;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    background: transparent;
    box-shadow: inherit;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li {
    padding: 3px 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li {
    padding: 3px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li a {
    padding: 2px 10px;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li ul.submenu li a {
    padding: 2px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    color: #dce1e5;
  }
}
@media only screen and (max-width: 767px) {
  .header-area .header-wrapper .mainmenunav ul.mainmenu li a {
    color: #dce1e5;
  }
}
.humberger-menu span {
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .humberger-menu span {
    font-size: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .humberger-menu span {
    font-size: 28px;
  }
}
.mainmenunav ul.mainmenu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.mainmenunav ul.mainmenu > li {
  margin: 0 15px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mainmenunav ul.mainmenu > li {
    margin: 0 12px;
  }
}
.mainmenunav ul.mainmenu > li > a {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0;
  display: inline-block;
}
.mainmenunav ul.mainmenu > li > ul.submenu {
  min-width: 240px;
  height: auto;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -2;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 12px 0;
  border-radius: 4px;
}
.mainmenunav ul.mainmenu > li > ul.submenu li a {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 20px;
  font-size: 14px;
  display: block;
  color: #1f1f25;
  margin: 0 10px;
  border-radius: 3px;
}
.mainmenunav ul.mainmenu > li > ul.submenu li:hover a {
  color: var(--color-primary);
  background: rgba(249, 0, 77, 0.07);
}
.mainmenunav ul.mainmenu > li:hover > ul.submenu {
  opacity: 1;
  visibility: visible;
  z-index: 90;
}
.mainmenunav ul.mainmenu > li:hover > a {
  color: var(--color-primary);
}
.mainmenunav ul.mainmenu > li:first-child {
  margin-left: 0;
}
.mainmenunav ul.mainmenu > li:last-child {
  margin-right: 0;
}
.humberger-menu span.text-white {
  color: #ffffff !important;
}
.rn-popup-mobile-menu {
  z-index: 9999;
  position: fixed;
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.rn-popup-mobile-menu .inner {
  width: 320px;
  z-index: 999;
  position: absolute;
  background-color: #fff;
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgba(149, 161, 158, 0.7);
  opacity: 0;
  left: -150px;
  transition: all 0.5s ease-out;
}
.rn-popup-mobile-menu .inner .popup-menu-top {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rn-popup-mobile-menu .inner .popup-menu-top .logo img {
  max-height: 56px;
}
.rn-popup-mobile-menu .inner .popup-menu-top .close-menu span {
  color: var(--color-body);
  font-size: 30px;
  cursor: pointer;
}
.rn-popup-mobile-menu ul.mainmenu {
  padding: 5px 20px;
}
.rn-popup-mobile-menu ul.mainmenu > li {
  border-bottom: 1px solid var(--color-border);
}
.rn-popup-mobile-menu ul.mainmenu > li:last-child {
  border: 0 none;
}
.rn-popup-mobile-menu ul.mainmenu li {
  margin: 0;
  padding: 12px 0;
}
.rn-popup-mobile-menu ul.mainmenu li a {
  font-size: 16px;
  font-weight: 500;
  color: #1f1f25;
}
.rn-popup-mobile-menu ul.mainmenu li.has-dropdown {
  position: relative;
}
.rn-popup-mobile-menu ul.mainmenu li.has-dropdown > a {
  position: relative;
  display: block;
}
.rn-popup-mobile-menu ul.mainmenu li.has-dropdown > a::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: #1f1f25 transparent transparent transparent;
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rn-popup-mobile-menu ul.mainmenu li.has-dropdown .submenu {
  display: none;
  padding-left: 20px;
  padding-top: 10px;
}
.rn-popup-mobile-menu ul.mainmenu li .submenu li {
  margin: 0;
  padding: 3px 0;
}
.rn-popup-mobile-menu ul.mainmenu li .submenu li a {
  font-size: 14px;
  padding: 2px 10px;
  color: #1f1f25;
}
a.btn-transparent {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  padding-bottom: 3px;
}
a.btn-transparent:hover {
  color: var(--color-primary);
}
a.btn-transparent::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  opacity: 0.75;
}
a.btn-transparent:hover::after {
  width: 100%;
}
a.rn-btn {
  padding: 0 23px;
  height: 40px;
  display: inline-block;
  line-height: 34px;
  border: 2px solid var(--color-primary);
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  z-index: 2;
  color: var(--color-primary);
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
a.rn-btn.btn-opacity {
  border: 2px solid var(--color-opacity-1);
  color: var(--color-white);
}
a.rn-btn:hover {
  border: 2px solid var(--color-primary);
  transform: translateY(-3px);
  color: #ffffff;
  background: var(--color-primary);
}
a.btn-default {
  color: var(--color-white);
  font-size: 16px;
  // text-transform: uppercase;
  letter-spacing: 2px;
  padding: 12px 30px;
  border-radius: 500px;
  display: inline-block;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  background-size: 152% 100%;
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
}
a.btn-default:hover {
  transition: all 0.4s ease-in-out;
  background: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
}
a.btn-default.btn-large {
  padding: 20px 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  a.btn-default.btn-large {
    padding: 16px 35px;
  }
}
@media only screen and (max-width: 767px) {
  a.btn-default.btn-large {
    padding: 16px 35px;
  }
}
a.btn-default.btn-border {
  background-color: transparent;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
}
a.btn-default.btn-border.btn-opacity {
  border: 2px solid var(--color-opacity-1);
  color: var(--color-white);
}
a.btn-default.btn-border:hover {
  color: var(--color-white);
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}
.section-title h2.title {
  font-size: 40px;
  font-weight: 600;
  line-height: 62px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2.title {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-title h2.title {
    font-size: 28px;
    line-height: 43px;
  }
}
.section-title p {
  font-size: 18px;
  line-height: 30px;
  color: var(--color-gray);
}
@media only screen and (max-width: 767px) {
  .section-title p br {
    display: none;
  }
}
.section-title span.subtitle {
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 12px;
}
@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 36px;
    margin-bottom: 7px;
  }
}
ul.social-share {
  padding: 0;
  margin: 0;
  margin: -6px;
}
ul.social-share li {
  margin: 6px;
}
ul.social-share li a {
  width: 40px;
  display: inline-block;
  height: 40px;
  border: 2px solid rgba(198, 201, 216, 0.75);
  line-height: 35px;
  color: rgba(198, 201, 216, 0.75);
  border-radius: 100%;
  text-align: center;
  font-size: 14px;
}
ul.social-share li:hover a {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: #ffffff;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-3px);
}
ul.social-share.social-style--2 li a {
  border: 0 none;
  width: 30px;
  height: auto;
  text-align: left;
  line-height: inherit;
  font-size: 16px;
}
ul.social-share.social-style--2 li:hover a {
  background: transparent;
  box-shadow: none;
}
.transparent_link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.im_portfolio {
  position: relative;
  z-index: 2;
  overflow: hidden;
  max-height: 520px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}
.im_portfolio::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  display: block;
  z-index: 1;
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(15, 15, 15, 0),
    rgba(15, 15, 15, 0.75) 100%
  );
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
}
.im_portfolio::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  display: block;
  z-index: 1;
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    var(--color-primary) 100%
  );
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
  opacity: 0;
}
.im_portfolio .thumbnail_inner .thumbnail a {
  display: block;
}
.im_portfolio .thumbnail_inner .thumbnail a img {
  transition: transform 1s ease, opacity 0.5s ease 0.25s;
  border-radius: 10px;
  width: 100%;
}
.im_portfolio .content .inner {
  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 30px 30px;
  z-index: 2;
  width: 100%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 767px) {
  .im_portfolio .content .inner {
    padding: 27px 24px;
  }
}
.im_portfolio .content .inner .portfolio_heading {
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}
.im_portfolio .content .inner .portfolio_heading .category_list {
  margin: -5px;
  margin-bottom: 5px;
}
.im_portfolio .content .inner .portfolio_heading .category_list a {
  color: #ffffff;
  opacity: 0.7;
  display: inline-block;
  margin: 5px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.im_portfolio .content .inner .portfolio_heading .title {
  color: #ffffff;
  font-size: 30px;
  font-size: "Poppins", sans-serif !important;
}
.im_portfolio .content .inner .portfolio_heading .title a {
  color: #ffffff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .im_portfolio .content .inner .portfolio_heading .title {
    font-size: 24px;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .im_portfolio .content .inner .portfolio_heading .title {
    font-size: 24px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .im_portfolio .content .inner .portfolio_heading .title {
    font-size: 22px;
    margin-bottom: 0;
  }
}
.im_portfolio .content .inner .portfolio_hover {
  opacity: 0;
  position: absolute;
  bottom: 35px;
  margin-top: 10px;
  transform: translateY(20px);
  line-height: 1.5em;
  max-width: 80%;
  transition: opacity 0.18s cubic-bezier(0.05, 0.2, 0.1, 1),
    transform 0.18s cubic-bezier(0.05, 0.2, 0.1, 1);
  height: 62px;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .im_portfolio .content .inner .portfolio_hover {
    bottom: 43px;
  }
}
.im_portfolio .content .inner .portfolio_hover p {
  color: #ffffff;
  opacity: 0.7;
}
.im_portfolio:hover {
  transform: translateY(-10px);
  box-shadow: 0 25px 55px rgba(253, 71, 102, 0.22);
}
.im_portfolio:hover::before {
  opacity: 0;
}
.im_portfolio:hover::after {
  opacity: 1;
}
.im_portfolio:hover .thumbnail_inner .thumbnail a img {
  transform: scale(1.1);
  transition: all 9s cubic-bezier(0.1, 0.2, 7, 1);
}
.im_portfolio:hover .content .inner .portfolio_heading {
  transform: translateY(-62px);
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}
@media only screen and (max-width: 767px) {
  .im_portfolio:hover .content .inner .portfolio_heading {
    transform: translateY(-91px);
  }
}
.im_portfolio:hover .content .inner .portfolio_hover {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1) 0.15s,
    transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1) 0.15s;
}
.service-main-wrapper .service.service__style--2 {
  padding: 30px 35px;
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  z-index: 2;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service-main-wrapper .service.service__style--2 {
    padding: 30px 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-main-wrapper .service.service__style--2 {
    padding: 30px 25px;
  }
}
@media only screen and (max-width: 767px) {
  .service-main-wrapper .service.service__style--2 {
    padding: 30px 14px;
  }
}
@media only screen and (max-width: 575px) {
  .service-main-wrapper .service.service__style--2 {
    padding: 30px 18px;
    margin-top: 30px;
  }
}
.service-main-wrapper .service.service__style--2 .icon {
  font-size: 45px;
  font-weight: 400;
  margin-bottom: 23px;
  color: var(--color-primary);
  text-align: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-main-wrapper .service.service__style--2 .icon {
    margin-bottom: 11px;
  }
}
.service-main-wrapper .service.service__style--2 .icon svg {
  stroke-width: 1 !important;
  width: 47px;
  height: 47px;
}
.service-main-wrapper .service.service__style--2 .content h3.title {
  margin-bottom: 19px;
  font-weight: 600;
  font-size: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-main-wrapper .service.service__style--2 .content h3.title {
    margin-bottom: 8px;
  }
}
.service-main-wrapper .service.service__style--2 .content p {
  color: var(--color-gray);
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}
.service-main-wrapper .service.service__style--2:hover {
  box-shadow: 0px 2px 70px 0px rgba(253, 71, 102, 0.05);
  transform: translateY(-5px);
}
.service-main-wrapper .service.service__style--2.text-left {
  padding: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service-main-wrapper .service.service__style--2.text-left {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-main-wrapper .service.service__style--2.text-left {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-main-wrapper .service.service__style--2.text-left {
    padding: 40px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .service-main-wrapper .service.service__style--2.text-left {
    padding: 40px 30px;
  }
}
@media only screen and (max-width: 575px) {
  .service-main-wrapper .service.service__style--2.text-left {
    padding: 32px 23px;
  }
}
.service-main-wrapper .service.service__style--2.text-left .icon {
  justify-content: left;
}
.service-main-wrapper
  > [class*="col"]:nth-child(2)
  .service.service__style--2
  .icon {
  color: var(--color-secondary);
}
.service-main-wrapper
  > [class*="col"]:nth-child(3)
  .service.service__style--2
  .icon {
  color: #12d8df;
}
.service-main-wrapper
  > [class*="col"]:nth-child(4)
  .service.service__style--2
  .icon {
  color: #59c98d;
}
.service-main-wrapper
  > [class*="col"]:nth-child(5)
  .service.service__style--2
  .icon {
  color: #6f41f6;
}
.service-main-wrapper
  > [class*="col"]:nth-child(6)
  .service.service__style--2
  .icon {
  color: #357ff6;
}
.service-white .service__style--1 {
  z-index: 2;
  text-align: center;
}
.service-white .service__style--1 .icon {
  margin-bottom: 22px;
}
.service-white .service__style--1 .icon svg {
  font-size: 60px;
  stroke-width: 1;
  color: var(--color-primary);
  width: 60px;
  height: 60px;
}
.service-white .service__style--1 .content h4.title {
  color: var(--color-white);
}
.service-white .service__style--1 .content p {
  color: #bfbdbd;
  padding: 0 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service-white .service__style--1 .content p {
    padding: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-white .service__style--1 .content p {
    padding: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-white .service__style--1 .content p {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .service-white .service__style--1 .content p {
    padding: 0;
  }
}
.slider-fixed--height {
  min-height: 1080px;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slider-fixed--height {
    min-height: 700px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-fixed--height {
    min-height: auto;
    padding: 60px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-fixed--height {
    min-height: auto;
    padding: 80px 0;
    padding-bottom: 60px;
  }
}
.slide .inner {
  padding-top: 80px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .slide .inner {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .slide .inner {
    padding-top: 30px;
  }
}
.slide .inner h1.title {
  font-size: 100px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #fff;
  line-height: 120px;
}
.Typewriter__cursor {
  color: #ffa800 !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide .inner h1.title {
    font-size: 100px;
    line-height: 112px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .inner h1.title {
    font-size: 90px;
    line-height: 103px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide .inner h1.title {
    font-size: 70px;
    line-height: 82px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .slide .inner h1.title {
    font-size: 50px;
    line-height: 70px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 575px) {
  .slide .inner h1.title {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 20px;
  }
}
.slide.slide-style-1 .inner {
  padding-top: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slide-style-1 .inner {
    padding-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .slide.slide-style-1 .inner {
    padding-top: 57px;
  }
}
@media only screen and (max-width: 575px) {
  .slide.slide-style-1 .inner {
    padding-top: 57px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slide-style-1 .inner h1.title {
    font-size: 68px;
    line-height: 81px;
    margin-bottom: 9px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.slide-style-1 .service-wrapper.service-white {
    margin-top: 64px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-wrapper .service-wrapper {
    margin-bottom: -20px;
  }
}
.slide .service {
  margin-top: 85px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .service {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide .service {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .slide .service {
    margin-top: 20px;
  }
}
.slider-activation {
  position: relative;
}
.thumbnail img {
  border-radius: 5px;
  box-shadow: 0 25px 65px rgba(0, 0, 0, 0.1);
}
.im_counterup {
  height: 280px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 10px;
}
.im_counterup .inner .icon {
  height: 50px;
  width: 50px;
  margin: 0 auto;
  line-height: 47px;
  border-radius: 100%;
}
.im_counterup .inner .icon svg {
  stroke-width: 1;
  font-size: 45px;
}
.im_counterup .inner .counter {
  font-size: 54px;
  font-weight: 600;
  line-height: 62px;
  margin-top: 20px;
  position: relative;
  display: inline-block;
  margin-bottom: 7px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .im_counterup .inner .counter {
    font-size: 44px;
    font-weight: 600;
    line-height: 52px;
  }
}
@media only screen and (max-width: 767px) {
  .im_counterup .inner .counter {
    font-size: 44px;
    font-weight: 600;
    line-height: 52px;
  }
}
.im_counterup .inner .counter::after {
  content: "+";
}
.im_counterup .inner .description {
  font-size: 18px;
  line-height: 28px;
}
.im_single_counterup .im_counterup .inner .icon svg {
  color: var(--color-primary);
  width: 40px;
  height: 38px;
}
.im_single_counterup:nth-child(2) .im_counterup .inner .icon svg {
  color: var(--color-secondary);
}
.im_single_counterup:nth-child(3) .im_counterup .inner .icon svg {
  color: var(--color-warning);
}
.im_single_counterup:nth-child(4) .im_counterup .inner .icon svg {
  color: var(--color-success);
}
.rn-testimonial-content .inner {
  padding-bottom: 20px;
}
.rn-testimonial-content .inner p {
  font-size: 30px;
  line-height: 48px;
  color: #18181b;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: -16px;
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-testimonial-content .inner p {
    font-size: 24px;
    line-height: 44px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-testimonial-content .inner p {
    font-size: 28px;
    line-height: 48px;
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .rn-testimonial-content .inner p {
    font-size: 20px !important;
    line-height: 37px !important;
    padding: 0;
    font-weight: 400;
  }
}
@media only screen and (max-width: 575px) {
  .rn-testimonial-content .inner p {
    font-size: 18px !important;
    line-height: 34px !important;
  }
}
.rn-testimonial-content .inner p::before {
  position: absolute;
  width: 177px;
  height: 100%;
  left: -64px;
  top: -57px;
  background-image: url(http://rainbowit.net/html/imroz/assets/images/client/image-1.png);
  content: "";
  opacity: 1;
  background-repeat: no-repeat;
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-testimonial-content .inner p::before {
    left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-testimonial-content .inner p::before {
    left: 0;
    top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .rn-testimonial-content .inner p::before {
    top: -15px;
    background-size: 100px;
    left: 50%;
    right: 0;
    width: 100%;
    margin-left: -48px;
  }
}
.rn-testimonial-content .author-info h6 {
  font-size: 18px;
  line-height: 50px;
  color: #8c8c8c;
  font-weight: 300;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .rn-testimonial-content .author-info h6 {
    line-height: 20px;
    font-size: 14px;
  }
}
.rn-testimonial-content .author-info h6 span {
  color: var(--color-primary);
  text-transform: uppercase;
  font-weight: 500;
}
ul.testimonial-thumb-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 510px;
  margin: 0px auto;
  margin-bottom: -30px;
  outline: none;
  border: 0 none;
}
@media only screen and (max-width: 575px) {
  ul.testimonial-thumb-wrapper {
    margin-bottom: -10px;
  }
}
ul.testimonial-thumb-wrapper li {
  padding-left: 15px;
  padding-right: 15px;
  flex-basis: 33.33%;
  margin-bottom: 30px;
  outline: none;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  ul.testimonial-thumb-wrapper li {
    flex-basis: 33.33%;
    margin-bottom: 14px;
    padding-left: 7px;
    padding-right: 7px;
  }
}
@media only screen and (max-width: 575px) {
  ul.testimonial-thumb-wrapper li {
    flex-basis: 50%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
}
ul.testimonial-thumb-wrapper li .thumb {
  position: relative;
  display: inline-block;
  width: 100%;
}
ul.testimonial-thumb-wrapper li .thumb img {
  border-radius: 6px;
  width: 100%;
  max-height: 140px;
  min-height: 140px;
  object-fit: cover;
}
ul.testimonial-thumb-wrapper li .thumb img:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
}
ul.testimonial-thumb-wrapper li .thumb::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  border-radius: 6px;
}
ul.testimonial-thumb-wrapper li a.active .thumb::after {
  background-color: rgba(255, 255, 255, 0);
}
ul.testimonial-thumb-wrapper li a.active .thumb img {
  transform: scale(1.1);
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 767px) {
  ul.testimonial-thumb-wrapper li a.active .thumb img {
    transform: none;
    object-fit: cover;
  }
}
ul.brand-style-2 {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 575px) {
  ul.brand-style-2 {
    margin: 0 -15px;
  }
}
ul.brand-style-2 li {
  padding: 25px;
  flex-basis: 20%;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  ul.brand-style-2 li {
    flex-basis: 33.33%;
    padding: 17px 10px;
  }
}
@media only screen and (max-width: 575px) {
  ul.brand-style-2 li {
    flex-basis: 33.33%;
    padding: 0 15px;
    margin-bottom: 16px;
  }
}
ul.brand-style-2 img {
  opacity: 0.8;
  transition: 0.3s;
  max-height: 110px;
  max-width: 150px;
  filter: grayscale(1);
  cursor: pointer;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  ul.brand-style-2 img {
    max-width: 129px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  ul.brand-style-2 img {
    max-height: 79px;
  }
}
@media only screen and (max-width: 767px) {
  ul.brand-style-2 img {
    max-height: 60px;
    max-width: 74px;
  }
}
@media only screen and (max-width: 575px) {
  ul.brand-style-2 img {
    max-height: initial;
  }
}
ul.brand-style-2 img:hover {
  transform: scale(1.1);
  opacity: 1;
  filter: grayscale(0);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .im-call-to-action-area h2 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .im-call-to-action-area h2 {
    font-size: 26px;
  }
}
.rn-progress-parent {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px var(--color-gray);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.rn-progress-parent.rn-backto-top-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.rn-progress-parent::after {
  position: absolute;
  font-family: "feather" !important;
  content: "\e914";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  color: var(--color-primary);
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.rn-progress-parent:hover::after {
  color: var(--color-primary);
}
.rn-progress-parent::before {
  position: absolute;
  font-family: "feather" !important;
  content: "\e914";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  opacity: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.rn-progress-parent:hover::before {
  opacity: 1;
}
.rn-progress-parent svg path {
  fill: none;
}
.rn-progress-parent svg.rn-back-circle path {
  stroke: var(--color-primary);
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.im_box {
  position: relative;
  z-index: 2;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}
.im_box::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  display: block;
  z-index: 1;
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(15, 15, 15, 0),
    rgba(15, 15, 15, 0.75) 100%
  );
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
}
.im_box::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85%;
  display: block;
  z-index: 1;
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    var(--color-primary) 100%
  );
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
  opacity: 0;
}
.im_box .thumbnail a {
  display: block;
}
.im_box .thumbnail a img {
  transition: transform 1s ease, opacity 0.5s ease 0.25s;
  border-radius: 10px;
}
.im_box .content .inner {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px 30px;
  z-index: 2;
  width: 100%;
  z-index: 3;
}
.im_box .content .inner .content_heading {
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}
.im_box .content .inner .content_footer {
  opacity: 0;
  position: absolute;
  bottom: 35px;
  margin-top: 10px;
  transform: translateY(20px);
  line-height: 1.5em;
  max-width: 80%;
  transition: opacity 0.18s cubic-bezier(0.05, 0.2, 0.1, 1),
    transform 0.18s cubic-bezier(0.05, 0.2, 0.1, 1);
}
.im_box .content .category_list {
  margin: -5px;
  margin-bottom: 5px;
}
.im_box .content .category_list a {
  color: #ffffff;
  opacity: 0.7;
  display: inline-block;
  margin: 5px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.im_box .content .title {
  margin-bottom: 0;
}
.im_box .content .title a {
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
@media only screen and (max-width: 767px) {
  .im_box .content .title a {
    font-size: 22px;
    line-height: 33px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .im_box .content .title a {
    font-size: 20px;
    line-height: 28px;
  }
}
.im_box:hover {
  transform: translateY(-10px);
  box-shadow: 0 25px 55px rgba(253, 71, 102, 0.22);
}
.im_box:hover::before {
  opacity: 0;
}
.im_box:hover::after {
  opacity: 1;
}
.im_box:hover .content .inner .content_heading {
  transform: translateY(-62px);
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}
.im_box:hover .content .inner .content_footer {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1) 0.15s,
    transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1) 0.15s;
}
.im_box:hover .thumbnail a img {
  transform: scale(1.1);
  transition: all 9s cubic-bezier(0.1, 0.2, 7, 1);
}
.footer-wrapper {
  position: relative;
}
.footer-style-01 .footer-link h4 {
  font-weight: 500;
  margin-bottom: 30px;
  color: var(--color-white);
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  .footer-style-01 .footer-link h4 {
    margin-bottom: 14px;
  }
}
.footer-style-01 .footer-link ul.ft-link {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer-style-01 .footer-link ul.ft-link li {
  color: #757575;
  font-size: 16px;
}
.footer-style-01 .footer-link ul.ft-link li a {
  color: #757575;
  font-size: 16px;
  padding: 5px 0;
}
.footer-style-01 .footer-link ul.ft-link li a:hover {
  color: var(--color-primary);
}
.footer-style-01 .footer-link ul.ft-link li + li {
  margin-top: 13px;
}
@media only screen and (max-width: 767px) {
  .footer-style-01 .footer-link ul.ft-link li + li {
    margin-top: 8px;
  }
}
.footer-style-01 .ft-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  height: 100%;
}
.footer-style-01 .ft-text .logo {
  margin-bottom: 30px;
}
.footer-style-01 .ft-text p {
  color: #757575;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
}
.footer-style-01 .ft-text p a {
  color: #757575;
}
.footer-style-01 .ft-text p a:hover {
  color: var(--color-primary);
}

.footer-area .logo img {
  max-width: 160px;
}
.footer-area .logo span {
  font-size: 30px;
  font-weight: 600;
  color: #dce1e5;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-inner {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .about-inner {
    padding-top: 40px;
  }
}
.about-inner .icon {
  width: 60px;
  height: 60px;
  background: #25d366;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  line-height: 58px;
  font-size: 27px;
  margin-bottom: 17px;
}
.about-inner .icon svg {
  width: 38px;
  height: 38px;
}
.about-inner .section-title h2.title {
  line-height: 1.3;
  margin-bottom: 35px;
}
.about-inner .section-title p.description {
  font-weight: 300;
  color: var(--color-gray);
}
.about-wrapper .thumbnail {
  position: relative;
  z-index: 2;
}
.about-wrapper .thumbnail img {
  border-radius: 8px;
  box-shadow: none;
  // height: 500px !important;
  // object-fit: cover;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url("http://rainbowit.net/html/imroz/assets/fonts/fontawesome-free/webfonts/fa-brands-400.eot");
  src: url("http://rainbowit.net/html/imroz/assets/fonts/fontawesome-free/webfonts/fa-brands-400.eot#iefix")
      format("embedded-opentype"),
    url("http://rainbowit.net/html/imroz/assets/fonts/fontawesome-free/webfonts/fa-brands-400.woff2")
      format("woff2"),
    url("http://rainbowit.net/html/imroz/assets/fonts/fontawesome-free/webfonts/fa-brands-400.woff")
      format("woff"),
    url("http://rainbowit.net/html/imroz/assets/fonts/fontawesome-free/webfonts/fa-brands-400.ttf")
      format("truetype"),
    url("http://rainbowit.net/html/imroz/assets/fonts/fontawesome-free/webfonts/fa-brands-400.svg#fontawesome")
      format("svg");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmy15VFteOcEg.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmy15VGdeOcEg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmy15VF9eO.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJbecmNE.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJnecmNE.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecg.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUQjIg1_i6t8kCHKm459WxRxC7mw9c.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUQjIg1_i6t8kCHKm459WxRzS7mw9c.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUQjIg1_i6t8kCHKm459WxRxi7mw9c.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUQjIg1_i6t8kCHKm459WxRxy7mw9c.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_ZpC3gTD_u50.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_ZpC3g3D_u50.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_ZpC3gbD_u50.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_ZpC3gfD_u50.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gTD_u50.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gbD_u50.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3gTD_u50.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3g3D_u50.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3gbD_u50.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3gfD_u50.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3gnD_g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3gTD_u50.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3g3D_u50.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3gbD_u50.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3gfD_u50.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3gnD_g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/**
 * Error Page
 *
 * @format
 */

.error {
  font-family: "PT Sans Caption", sans-serif, "arial", "Times New Roman";
}
.error .clip .shadow {
  height: 180px; /*Contrall*/
}
.error .clip:nth-of-type(2) .shadow {
  width: 130px; /*Contrall play with javascript*/
}
.error .clip:nth-of-type(1) .shadow,
.error .clip:nth-of-type(3) .shadow {
  width: 250px; /*Contrall*/
}
.error .digit {
  width: 150px; /*Contrall*/
  height: 150px; /*Contrall*/
  line-height: 150px; /*Contrall*/
  font-size: 120px;
  font-weight: bold;
}
.error h2   /*Contrall*/ {
  font-size: 32px;
}
.error .msg /*Contrall*/ {
  top: -190px;
  left: 30%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 32px;
}
.error span.triangle    /*Contrall*/ {
  top: 70%;
  right: 0%;
  border-left: 20px solid #535353;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.error .container-error-404 {
  margin-top: 10%;
  position: relative;
  height: 250px;
  padding-top: 40px;
}
.error .container-error-404 .clip {
  display: inline-block;
  transform: skew(-45deg);
}
.error .clip .shadow {
  overflow: hidden;
}
.error .clip:nth-of-type(2) .shadow {
  overflow: hidden;
  position: relative;
  box-shadow: inset 20px 0px 20px -15px rgba(150, 150, 150, 0.8),
    20px 0px 20px -15px rgba(150, 150, 150, 0.8);
}

.error .clip:nth-of-type(3) .shadow:after,
.error .clip:nth-of-type(1) .shadow:after {
  content: "";
  position: absolute;
  right: -8px;
  bottom: 0px;
  z-index: 9999;
  height: 100%;
  width: 10px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(173, 173, 173, 0.8),
    transparent
  );
  border-radius: 50%;
}
.error .clip:nth-of-type(3) .shadow:after {
  left: -8px;
}
.error .digit {
  position: relative;
  top: 8%;
  color: white;
  background: #07b3f9;
  border-radius: 50%;
  display: inline-block;
  transform: skew(45deg);
}
.error .clip:nth-of-type(2) .digit {
  left: -10%;
}
.error .clip:nth-of-type(1) .digit {
  right: -20%;
}
.error .clip:nth-of-type(3) .digit {
  left: -20%;
}
.error h2 {
  color: #a2a2a2;
  font-weight: bold;
  padding-bottom: 20px;
}
.error .msg {
  position: relative;
  z-index: 9999;
  display: block;
  background: #535353;
  color: #a2a2a2;
  border-radius: 50%;
  font-style: italic;
}
.error .triangle {
  position: absolute;
  z-index: 999;
  transform: rotate(45deg);
  content: "";
  width: 0;
  height: 0;
}

/* Error Page */
@media (max-width: 767px) {
  /* Error Page */
  .error .clip .shadow {
    height: 100px; /*Contrall*/
  }
  .error .clip:nth-of-type(2) .shadow {
    width: 80px; /*Contrall play with javascript*/
  }
  .error .clip:nth-of-type(1) .shadow,
  .error .clip:nth-of-type(3) .shadow {
    width: 100px; /*Contrall*/
  }
  .error .digit {
    width: 80px; /*Contrall*/
    height: 80px; /*Contrall*/
    line-height: 80px; /*Contrall*/
    font-size: 52px;
  }
  .error h2   /*Contrall*/ {
    font-size: 24px;
  }
  .error .msg /*Contrall*/ {
    top: -110px;
    left: 15%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
  .error span.triangle    /*Contrall*/ {
    top: 70%;
    right: -3%;
    border-left: 10px solid #535353;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
  .error .container-error-404 {
    height: 150px;
  }
  /* Error Page */
}

/*--------------------------------------------Framework --------------------------------*/

.overlay {
  position: relative;
  z-index: 20;
} /*done*/
.ground-color {
  background: white;
} /*done*/
.item-bg-color {
  background: #eaeaea;
} /*done*/

/* Padding Section*/
.padding-top {
  padding-top: 10px;
} /*done*/
.padding-bottom {
  padding-bottom: 10px;
} /*done*/
.padding-vertical {
  padding-top: 10px;
  padding-bottom: 10px;
}
.padding-horizontal {
  padding-left: 10px;
  padding-right: 10px;
}
.padding-all {
  padding: 10px;
} /*done*/

.no-padding-left {
  padding-left: 0px;
} /*done*/
.no-padding-right {
  padding-right: 0px;
} /*done*/
.no-vertical-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}
.no-horizontal-padding {
  padding-left: 0px;
  padding-right: 0px;
}
.no-padding {
  padding: 0px;
} /*done*/
/* Padding Section*/

/* Margin section */
.margin-top {
  margin-top: 10px;
} /*done*/
.margin-bottom {
  margin-bottom: 10px;
} /*done*/
.margin-right {
  margin-right: 10px;
} /*done*/
.margin-left {
  margin-left: 10px;
} /*done*/
.margin-horizontal {
  margin-left: 10px;
  margin-right: 10px;
} /*done*/
.margin-vertical {
  margin-top: 10px;
  margin-bottom: 10px;
} /*done*/
.margin-all {
  margin: 10px;
} /*done*/
.no-margin {
  margin: 0px;
} /*done*/

.no-vertical-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}
.no-horizontal-margin {
  margin-left: 0px;
  margin-right: 0px;
}

.inside-col-shrink {
  margin: 0px 20px;
} /*done - For the inside sections that has also Title section*/
/* Margin section */

hr {
  margin: 0px;
  padding: 0px;
  border-top: 1px dashed #999;
}
/*--------------------------------------------FrameWork------------------------*/
.carousel-container {
  position: relative;
  width: 100%;
  height: 450px;
  padding: 20px 0;
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    height: 250px;
  }
}
.carousel {
  display: flex;
  width: 92%;
  height: 100%;
  position: relative;
  margin: auto;
}
.slider {
  flex: 0 0 auto;
  margin-right: 30px;
  position: relative;
  // background: rgba(0, 0, 0,0.5);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  left: 0;
  transition: 1s;
  overflow: hidden;
}
.sec-bg2 {
  // background: #030b17;
  background: linear-gradient(to right, #030b17 50%, #0c111b00),
    url(../public/assets/Documentary1.jpg) no-repeat right;
  background-size: contain;
  position: relative;
  min-height: auto;
  padding: 40px 0;
  padding-bottom: 60px;
  @media screen and (max-width: 600px) {
    background-size: cover;
  }
}

.slide-content {
  position: absolute;
  height: 100%;
  z-index: 2;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  align-content: center;
  @media screen and (max-width: 600px) {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-end;
  }
}
.movie-title {
  text-transform: capitalize;
  font-size: 60px !important;
  font-family: "Poppins", sans-serif;
  margin-top: 80px;
  letter-spacing: 2px;
  color: #fff;
  @media screen and (max-width: 600px) {
    font-size: 30px !important;
  }
}

.backIcon {
  z-index: 2 !important;
  position: relative !important;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700,800");

$primary-color: #cfd6e1;
$bg-color: #dce1e5;
$title-color: #9ac7fa;
// $background-image: url(https://i.ytimg.com/vi/oR-mzzIsHVE/maxresdefault.jpg);
// $background-image2: url("http://www.blastr.com/sites/blastr/files/back-to-the-future-part-ii-original.jpg");
// $background-image3: url("https://static1.squarespace.com/static/51b3dc8ee4b051b96ceb10de/t/58d86b3db3db2b57ce8f2986/1490578241899/?format=2500w");
$font-stack-content: "Montserrat", helvetica, arial, sans-serif;
$font-stack-header: "Montserrat", helvetica, arial, sans-serif;
$secondary-color: #2a9fff;

a {
  text-decoration: none;
}

.movie-card {
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  max-width: 500px;
  color: #cfd6e1;
  font-size: 14px !important;
  height: 300px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  justify-content: flex-end;
  margin: 2vh auto;
  border-radius: 8px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  @media screen and (max-width: 800px) {
    width: 95%;
    max-width: 95%;
  }
  @media screen and (max-width: 600px) {
    background-position: 50% 0%;
    background-size: cover;
    margin: 2vh auto;
  }

  &__overlay {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(
      to right,
      rgba(42, 159, 255, 0.2) 0%,
      rgba(33, 33, 32, 1) 60%,
      rgba(33, 33, 32, 1) 100%
    );
    background-blend-mode: multiply;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    @media screen and (max-width: 600px) {
      background: linear-gradient(
        to bottom,
        rgba(42, 159, 255, 0.2) 0%,
        rgba(33, 33, 32, 1) 60%,
        rgba(33, 33, 32, 1) 100%
      );
    }
  }
  &__share {
    padding: 1em;
    display: inline-block;
    width: 100%;
    max-width: 130px;
    @media screen and (max-width: 600px) {
      display: block;
      width: 100%;
    }
  }
  &__icon {
    color: #ffffff;
    mix-blend-mode: lighten;
    opacity: 0.4;
    background: none;
    padding: 0;
    &:hover {
      opacity: 1;
      mix-blend-mode: lighten;
    }
    &:not(:first-of-type) {
      margin-left: 5px;
    }
    i {
      font-size: 1.2em;
    }
  }
  &__content {
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    float: right;
    padding-right: 1.2em;
    padding-bottom: 1em;
    @media screen and (max-width: 1000px) {
      width: 50%;
    }
    @media screen and (max-width: 600px) {
      // margin-top: 4.2em;
      width: 100%;
      float: inherit;
      max-width: 100%;
      padding: 0 1em 1em;
    }
  }
  &__header {
    margin-bottom: 2em;
  }
  &__title {
    color: #ffffff;
    margin-bottom: 0.25em;
    opacity: 0.75;
    font-size: 2em !important;
  }
  &__info {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 0.8em;
    color: $secondary-color;
    line-height: 1;
    margin: 0;
    font-weight: 700;
    opacity: 0.5;
  }
  &__desc {
    font-weight: 300;
    opacity: 0.84;
    font-size: 14px !important;

    margin-bottom: 2em;
  }
}

// .movie-card h1,h2,h3 {
// 	font-family: $font-stack-header;
// 	text-transform: uppercase;
// 	letter-spacing: 2px;
// 	line-height: 1;
// 	font-weight: 400;
// }
.movie-card {
  .btn {
    padding: 0.5rem 2rem;
    background: var(--color-primary) !important;
    color: rgba(255, 255, 255, 1);
    // border-radius: 40px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .btn-icon {
    vertical-align: middle;
    font-size: 1.5em;
    padding-right: 0.5em;
  }

  .btn-outline:hover {
    // border-color: #dc3545;
    // color: #dc3545;
    border: 0;
    box-shadow: 0px 1px 8px 0px rgba(245, 199, 0, 0.2);
  }
}

.movie-card-reels {
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: contain;
  max-width: 300px;
  color: #cfd6e1;
  font-size: 14px !important;
  height: 500px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  justify-content: flex-end;
  margin: 2vh auto;
  border-radius: 8px;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  @media screen and (max-width: 800px) {
    width: 95%;
    max-width: 95%;
  }
  @media screen and (max-width: 600px) {
    background-position: 50% 0%;
    background-size: cover;
    margin: 2vh auto;
  }
  .btn {
    padding: 0.5rem 2rem;
    background: var(--color-primary) !important;
    color: rgba(255, 255, 255, 1);
    // border-radius: 40px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .btn-icon {
    vertical-align: middle;
    font-size: 1.5em;
    padding-right: 0.5em;
  }

  .btn-outline:hover {
    // border-color: #dc3545;
    // color: #dc3545;
    border: 0;
    box-shadow: 0px 1px 8px 0px rgba(245, 199, 0, 0.2);
  }
}
